import { EUCountriesWithCommonVATrule } from "./constants";

export function fieldValidation(errorField, touchedField, valueField) {
  if (errorField && touchedField) {
    return "error";
  } else {
    if (valueField) {
      return "filled";
    }
  }
}

export const mapFormDataToPilot = (userData) => {
  if (userData) {
    const userProfileData = {
      name: userData.name,
      email: userData.email,
      phone: userData.phone || null,
      searchName: userData.searchName || [],
      location: userData.location || null,
      city: userData.city.toLowerCase() || null,
      state: userData.state || null,
      equipment: userData.equipment || null,
      otherEquipmentType: userData.otherEquipmentType || null,
      associatedPilotsNb: userData.associatedPilotsNb,
      sensorsCameras: userData.sensorsCameras || null,
      megapixels: userData.megapixels || null,
      mappingProcessing: userData.mappingProcessing || null,
      flightHours: userData.flightHours || null,
      dailyCharge: userData.dailyCharge || null,
      sqkmCharge: userData.sqkmCharge || null,
      preferredPaymentMethods: userData.preferredPaymentMethods || null,
      availableLocations: userData.availableLocations || [],
      insurance: userData.insurance || false,
      permissionToFly: userData.permissionToFly || false,
      registeredBusiness: userData.registeredBusiness || false,
      EASACertified2024: userData.EASACertified2024 || false,
      profileImageURL: userData.profileImageURL || null,
      droneLicenseURL: userData.droneLicenseURL || null,
      originalLicenseFile: userData.originalLicenseFile || null,
      cvURL: userData.cvURL || null,
      originalCvFile: userData.originalCvFile || null,
      moreDetails: userData.moreDetails || null,
      instagram: userData.instagram || null,
      twitter: userData.twitter || null,
      linkedin: userData.linkedin || null,
      referralSource: userData.referralSource || null,
      jobRadius: userData.jobRadius || 0,
      postalCode: userData.postalCode || null,
      company: userData.company || null,
      registrationNo: userData.registrationNo || null,
      address: userData.address || null,
      billingAddress: userData.billingAddress || null,
      ambassador: userData.ambassador ? true : false,
      education: userData.education || null,
      industry: userData.industry || [],
      skills: userData.skills || [],
      role: userData.role,
      lidarType: userData.lidarType || null,
      groundCaptureEquipment: userData.groundCaptureEquipment || [],
      baseStations: userData.baseStations || [],
      gcpExperience: userData.gcpExperience || false,
      isEUCountry:
        (EUCountriesWithCommonVATrule.includes(userData.location) && true) ||
        false,
    };

    return userProfileData;
  }
  return null;
};

export const mapFormDataToAccount = (userData) => {
  if (userData) {
    const userAccData = {
      email: userData.email,
    };

    if (userData.password) {
      userAccData.password = userData.password;
    }

    return userAccData;
  }

  return null;
};

export const preventEnterSubmit = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};
