import { navigate } from "gatsby";
import firebase from "src/firebase";
import React, { useState } from "react";
import { registerDailyReport } from "../../services/handle-operations";
import DailyReportingForm from "../../components/forms/DailyReportingForm";
import IndexPage from "../../components/global/IndexPage";
import { reportType } from "../../lib/constants";
import { Box, Container } from "@mui/material";
import AppButton from "../../components/global/elements/AppButton";
import { boxShadowStyle } from "../../lib/styleConstants";

const styles = {
  root: {
    marginTop: "5vh",
    marginBottom: "5vh",
    padding: "50px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: "20px",
    flexDirection: { xs: "column", md: "row" },
    "& button": {
      marginBottom: "10px",
    },
  },
  buttonSelection: {
    cursor: "unset",
    borderRadius: 0,
  },
};

const DailyReportingPage = () => {
  const [type, setType] = useState(null);

  const handleSubmit = (value) => {
    setType(value);
  };
  const submitForm = (values, { setSubmitting }) => {
    const userData = values;

    registerDailyReport(firebase, userData)
      .then(() => {
        navigate("/daily-reporting/success", {
          state: {
            registrationCompleted: true,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setSubmitting(false));
  };

  const getButtonStyle = (reportType, selectedReportType) => {
    // Check which report type is selected and update the style accordingly
    if (selectedReportType === null) {
      return "black";
    } else if (reportType === selectedReportType) {
      return "primary";
    } else {
      return "inactive";
    }
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ ...styles.root, ...boxShadowStyle }}>
        <IndexPage
          message="Daily Report"
          subMessage1="Choose whether you are filling the capture or the upload status then
          fill in the following fields"
        />
        <Box sx={styles.buttons}>
          <AppButton
            look={getButtonStyle(reportType.capture, type)}
            onClick={() => handleSubmit(reportType.capture)}
            label="Capture report"
          />
          <AppButton
            look={getButtonStyle(reportType.upload, type)}
            label="Upload report"
            onClick={() => handleSubmit(reportType.upload)}
          />
        </Box>
        <DailyReportingForm submitHandler={submitForm} type={type} />
      </Container>
    </>
  );
};

export default DailyReportingPage;
