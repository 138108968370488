import PropTypes from "prop-types";
import React from "react";
import theme from "../../theme";
import { Box, Typography } from "@mui/material";

const styles = {
  title: {
    marginBottom: "0 !important",
  },

  contentWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "25px",

    "& row": {
      width: "100%",
    },
    "& hr": {
      maxWidth: "100%",
      width: "100%",
      borderColor: theme.palette.grey.lightWarmGrey,
      border: "1px solid #cacaca",
      "& .dark": {
        borderColor: theme.palette.black.darkSeaBlack,
      },
      margin: { xs: "15px 0", lg: "30px 0" },
    },
  },
  alignMiddle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    marginTop: "50px",
  },
};

const IndexPage = ({ message, subMessage1, subMessage2, hrTag }) => {
  return (
    <>
      {/* routeType can be public, private, auth */}
      <Box sx={{ ...styles.contentWrapper, ...styles.alignMiddle }}>
        <Typography variant="h3" sx={styles.title}>
          {message}
        </Typography>
        {subMessage1 && (
          <Typography variant="subtitle1">{subMessage1}</Typography>
        )}
        {subMessage2 && (
          <Typography variant="body1" sx={styles.info}>
            {subMessage2}
          </Typography>
        )}
        {hrTag && <hr />}
      </Box>
    </>
  );
};

IndexPage.propTypes = {
  message: PropTypes.string.isRequired,
  subMessage1: PropTypes.string,
  subMessage2: PropTypes.string,
  hrTag: PropTypes.bool,
};
IndexPage.defaultProps = {
  subMessage1: undefined,
  subMessage2: undefined,
  hrTag: false,
};

export default IndexPage;
